const DocMenuConfig = [
  {
    heading: "DASHBOARD",
    route: "/",
    pages: [
      {
        sectionTitle: "Dashboard",
        route: "/dashboard",
        svgIcon: "media/icons/duotune/layouts/lay001.svg",
        sub: [
          {
            heading: "Genel",
            route: "/dashboard",
            name: 'geneldashboard',
          },
          {
            heading: "Web Sitesi Analitiği",
            route: "/web-site-analytics"
          },
          {
            heading: "Finans Performansı",
            route: "/finance-performance"
          },
        ]
      }
    ]
  },
  {
    heading: "APP",
    route: "/app",
    pages: [
      {
        sectionTitle: "Sabit Değerler",
        route: "/fixed",
        svgIcon: "media/icons/duotune/general/gen019.svg",
        sub: [
          {
            heading: "Şehirler",
            route: "/cities"
          },
          {
            heading: "Okullar",
            route: "/schools"
          },
        ]
      },
      {
        sectionTitle: "Oyunlaştırma",
        route: "/gamification",
        svgIcon: "media/icons/duotune/general/gen019.svg",
        sub: [
          {
            heading: "Rozetler",
            route: "/badges"
          }
        ]
      },
      {
        heading: "Eğitmenler",
        route: "/egitmenler",
        svgIcon: "media/icons/duotune/communication/com014.svg",
      },
      {
        heading: "Başarılarımız",
        route: "/ourvictories",
        svgIcon: "media/icons/duotune/general/gen020.svg",
      },
    ]
  },
  {
    heading: "LMS",
    route: "/lms",
    pages: [
      {
        heading: "Eğitim Paketleri",
        route: "/packages",
        svgIcon: "media/icons/duotune/abstract/abs038.svg",
      },
      {
        heading: "Dersler",
        route: "/lessons",
        svgIcon: "media/icons/duotune/abstract/abs027.svg",
      },
      {
        heading: "Konular",
        route: "/topics",
        svgIcon: "media/icons/duotune/abstract/abs029.svg",
      },
      {
        heading: "Videolar",
        route: "/videos",
        svgIcon: "media/icons/duotune/arrows/arr027.svg",
      },
    ]
  },
  {
    heading: "E-Ticaret",
    route: "/e-commerce",
    pages: [
      {
        heading: "Markalar",
        route: "/brands",
        svgIcon: "media/icons/duotune/ecommerce/ecm004.svg",
      },
    ]
  },

];

export default DocMenuConfig;