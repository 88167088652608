
import { defineComponent } from "vue";
import { toolbarWidthFluid } from "@/core/helpers/config";
import { Mutations } from "@/store/enums/StoreEnums";
import store from '@/store';
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    title: String,
  },
  setup() {
    const router = useRouter();
    const resetPackage = () => {
     

      Swal.fire({
        title: 'Taslak Halindeki paketi sıfırlamak istediğinize emin misiniz?',
        
        showCancelButton: true,
        confirmButtonText: 'Eminim',
        cancelButtonText: 'Hayır',
        customClass:{
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          Swal.fire('Başarılı!', '', 'success').then(()=>{
              //FIX THİS CODE !!!!!!!!!!
             location.reload();
          });
          store.commit(Mutations.RESET_PACKAGE,{});
         

        } else if (result.isDenied) {
          console.log("sdas")
        }
      })
      
    }
    return {
      toolbarWidthFluid,
      resetPackage
    };
  },
});
